import $ from 'jquery';
import _ from 'lodash';

function call(method, url, data) {
    return $.ajax({
        method,
        url,
        processData: true,
        dataType: 'json',
        contentType: 'application/json',
        data: data && JSON.stringify(data),
    });
}

export default {
    call,
    get: _.partial(call, 'get'),
    post: _.partial(call, 'post'),
    put: _.partial(call, 'put'),
    patch: _.partial(call, 'patch'),
    delete: _.partial(call, 'delete'),
}