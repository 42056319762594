import http from './http';
import _ from 'lodash';
import handleNalError from './handleNalError.js';

async function nalRequest(method, url, data, name = '') {
    try {
        const response = await http.call(method, url, data);

        if (response && response.redirect) {
            window.location.replace(response.redirect);
        }
        return response;
    } catch (error) {
        handleNalError(error, 'nal');
        return error;
    }
}

export default {
    call: nalRequest,
    get: _.partial(nalRequest, 'get'),
    post: _.partial(nalRequest, 'post'),
    put: _.partial(nalRequest, 'put'),
    patch: _.partial(nalRequest, 'patch'),
    delete: _.partial(nalRequest, 'delete'),
};
